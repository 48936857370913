import React, { forwardRef, ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import "./b2x-telia-p.scss";

export const ParagraphVariant = {
  PARAGRAPH100: "paragraph-100",
  PARAGRAPH200: "paragraph-200",
  PREAMBLE100: "preamble-100",
  PREAMBLE200: "preamble-200",
  ADDITIONAL100: "additional-100",
  OVERLINE100: "overline-100",
  OVERLINE200: "overline-200",
} as const;

type ParagraphVariantType = typeof ParagraphVariant[keyof typeof ParagraphVariant];

type Props = React.HTMLProps<HTMLParagraphElement> & {
  children: ReactNode;
  variant?: ParagraphVariantType;
  className?: string;
  disableHyphenation?: boolean;
  ["data-testid"]?: string;
};
const TeliaPComponent = <T extends HTMLParagraphElement>(
  {
    children,
    variant = ParagraphVariant.PARAGRAPH100,
    className = "",
    disableHyphenation = false,
    ...rest
  }: Props,
  ref: React.ForwardedRef<T>
) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-p", "components-paragraph--docs");
  }, []);

  const noHyphensClass = disableHyphenation ? "b2x-telia-p--no-hyphens" : "";
  return (
    <p
      ref={ref}
      className={`b2x-telia-p b2x-telia-p--${variant} ${noHyphensClass} ${className}`}
      {...rest}
    >
      {children}
    </p>
  );
};

export const TeliaP = forwardRef(TeliaPComponent);
TeliaP.displayName = "TeliaP";
